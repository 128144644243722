import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo';
import {Container, Row, Col} from 'styled-bootstrap-grid';
import colors from '../styles/colors';
import fonts from '../styles/fonts';
import styled from 'styled-components';
import { Link } from 'gatsby';


const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-grow: 1;
  padding-left: 30px;
  padding-right: 30px;
  @media(min-width: 992px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const PageTitle = styled.h1`
  color: ${colors.grey};
  ${fonts.CormorantGaramondLight};
  font-size: 40px;
  line-height: 48px;
  margin: auto;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 25px;
  box-sizing: border-box;
  @media(min-width: 992px) {
      font-size: 50px;
      line-height: 58px;
      margin-top: 150px;
      padding-left: 30px;
      padding-right: 30px;
      max-width: 80%;
  }
`;

const StyledLink = styled(Link)`
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        border: none;
    }  
`;

const OrderButton = styled.button`
    margin: auto;
    margin-bottom: 100px;
    height: 44px;
    width: 168px;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    border: 1px solid ${colors.green};
    background: transparent;
    color: ${colors.grey};
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        background: ${colors.green};
        color: ${colors.white};
    }  
`;

const Order = styled.div`
    ${fonts.MontserratMedium};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
`;

const NotFoundPage = () => {
    return (
      <Layout>
        <SEO title='Coming Soon.'
             description='This page is coming soon. Please get in touch for more information.'/>
             <StyledContainer>
               <Row>
                 <Col>
                  <PageTitle>This page is coming soon. Please get in touch for more information.</PageTitle>
                  <StyledLink to={'/contact'}>
                    <OrderButton>
                        <Order> Contact us </Order>
                    </OrderButton>
                  </StyledLink>
                 </Col>
               </Row>
             </StyledContainer>
      </Layout>
    );
}

export default NotFoundPage;
